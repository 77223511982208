import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const PartialNavLink = React.forwardRef(
    ({ children, activeClassName, className, partialPaths, ...rest }, ref) => {
        const getLinkProps = linkProperties => {
            const { isCurrent, isPartiallyCurrent, location } = linkProperties;
            const partialPathMatches = partialPaths.some(partialPath =>
                location.pathname.startsWith(partialPath)
            );
            const isActive =
                isCurrent || isPartiallyCurrent || partialPathMatches;

            if (isActive) {
                return {
                    className: `${className} ${activeClassName}`,
                };
            }

            return { className };
        };

        return (
            <Link ref={ref} getProps={getLinkProps} {...rest}>
                {children}
            </Link>
        );
    }
);

PartialNavLink.propTypes = {
    children: PropTypes.node.isRequired,
    activeClassName: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
    partialPaths: PropTypes.arrayOf(PropTypes.string),
};

PartialNavLink.defaultProps = {
    style: {},
    activeClassName: '',
    className: '',
    partialPaths: [],
};

export default PartialNavLink;
