import React, { useContext, useRef, useLayoutEffect } from 'react';
import ApplicationContext from '@contexts/ApplicationContext';
import useRect from '@hooks/useRect';
import PropTypes from 'prop-types';
import Navbar from '../Navbar';

// IMPORTANT: Reconsider if this component is actually needed, we can just use
// the Navbar component directly in the layout or any other template component.

const Header = ({ fixed }) => {
    const { setAppState } = useContext(ApplicationContext);
    const headerRef = useRef(null);
    const headerRect = useRect(headerRef);

    useLayoutEffect(() => {
        setAppState(currentState => ({
            ...currentState,
            headerHeight: headerRect.height,
        }));
    }, [headerRect]);

    return (
        <header ref={headerRef}>
            <Navbar fixed={fixed} />
        </header>
    );
};

Header.defaultProps = {
    fixed: false,
};

Header.propTypes = {
    fixed: PropTypes.bool,
};

export default Header;
