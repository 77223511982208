import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from '@components/core/Text';
import styles from './privacy-policy.module.scss';

const PrivacyPolicy = () => {
    const {
        list: { privacyPolicyList },
    } = useStaticQuery(graphql`
        query GetPrivacyPolicyList {
            list: allMarkdownRemark(
                filter: {
                    fields: { slug: { glob: "/util-list/*" } }
                    frontmatter: { listTitle: { eq: "PrivacyPolicy" } }
                }
            ) {
                privacyPolicyList: nodes {
                    info: frontmatter {
                        privacyPolicy {
                            title
                            privacyPolicyFile {
                                publicURL
                            }
                        }
                    }
                }
            }
        }
    `);
    const formatedList = privacyPolicyList[0];
    return (
        <div className={styles.container}>
            {formatedList.info.privacyPolicy.map(
                ({ title, privacyPolicyFile }) => {
                    return (
                        <a
                            href={privacyPolicyFile.publicURL}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.privacyPolicy}
                            key={`item-privacy-policy-${title}`}
                        >
                            <Text as="p" size="paragraphSm">
                                {title}
                            </Text>
                        </a>
                    );
                }
            )}
        </div>
    );
};

export default PrivacyPolicy;
