import React from 'react';
import propTypes from 'prop-types';
import cx from 'classnames';
import Text from '@components/core/Text';
import styles from './eyebrow.module.scss';

const Eyebrow = ({ children, className, casing, normalSpacing, size }) => {
    const classes = cx(
        {
            [styles[casing]]: true,
            [styles.normalSpacing]: normalSpacing,
        },
        styles.eyebrow,
        className
    );

    return (
        <Text as="p" size={size} className={classes}>
            {children}
        </Text>
    );
};

Eyebrow.propTypes = {
    children: propTypes.node.isRequired,
    className: propTypes.string,
    casing: propTypes.oneOf([
        'default',
        'uppercase',
        'lowercase',
        'capitalize',
    ]),
    normalSpacing: propTypes.bool,
    size: propTypes.oneOf(['paragraphXs', 'paragraphSm', 'paragraphMd']),
};

Eyebrow.defaultProps = {
    className: '',
    casing: 'default',
    size: 'paragraphSm',
    normalSpacing: false,
};

export default Eyebrow;
