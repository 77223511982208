import React from 'react';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import cx from 'classnames';
import styles from './Column.module.scss';

const generateColumnClass = (breakpoint, item, col, sm, md, l, xl) => {
    if (xl && breakpoint.xl) return `col-xl-${xl}`;
    if (l && breakpoint.l) return `col-l-${l}`;
    if (md && breakpoint.md) return `col-md-${md}`;
    if (sm && breakpoint.sm) return `col-sm-${sm}`;
    if (col) return `col-${col}`;

    return `col-${item}`;
};

const generateOffsetClass = (breakpoint, offset, sm, md, l, xl) => {
    if (xl && breakpoint.xl) return `offset-xl-${xl}`;
    if (l && breakpoint.l) return `offset-l-${l}`;
    if (md && breakpoint.md) return `offset-md-${md}`;
    if (sm && breakpoint.sm) return `offset-sm-${sm}`;
    if (offset) return `offset-${offset}`;

    return null;
};

const Column = ({
    children,
    className,
    item,
    col,
    sm,
    md,
    l,
    xl,
    gutter,
    offset,
    offsetSm,
    offsetMd,
    offsetL,
    offsetXl,
    ...rest
}) => {
    const breakpoint = useBreakpoint();
    const columnClass = generateColumnClass(
        breakpoint,
        item,
        col,
        sm,
        md,
        l,
        xl
    );

    const offsetClass = generateOffsetClass(
        breakpoint,
        offset,
        offsetSm,
        offsetMd,
        offsetL,
        offsetXl
    );

    const classes = cx(styles.col, columnClass, offsetClass, className);
    return (
        <div className={classes} {...rest}>
            {children}
        </div>
    );
};

Column.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    item: PropTypes.string,
    col: PropTypes.string,
    sm: PropTypes.string,
    md: PropTypes.string,
    l: PropTypes.string,
    xl: PropTypes.string,
    className: PropTypes.string,
    gutter: PropTypes.bool,
    offset: PropTypes.string,
    offsetSm: PropTypes.string,
    offsetMd: PropTypes.string,
    offsetL: PropTypes.string,
    offsetXl: PropTypes.string,
};

Column.defaultProps = {
    item: '4',
    col: '',
    sm: '',
    md: '',
    l: '',
    xl: '',
    className: '',
    gutter: true,
    offset: undefined,
    offsetSm: '',
    offsetMd: '',
    offsetL: '',
    offsetXl: '',
    children: '',
};

// eslint-disable-next-line import/prefer-default-export
export { Column };
