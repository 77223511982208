import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Slide } from '@transitions';
import ApplicationContext from '@contexts/ApplicationContext';
import ZemogaLogoDark from '@assets/images/logo-dark.svg';
import ZemogaLogoLight from '@assets/images/logo-light.svg';

import styles from './fixed-navbar.module.scss';
import HamburgerButton from '../HamburgerButton/HamburgerButton';

function FixedNavbar({ isVisible, fixed }) {
    const { appState } = useContext(ApplicationContext);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const closeMobileMenu = () => setIsMenuOpen(false);

    const themeStyle =
        appState.navBarTheme === 'light'
            ? styles.navbarLight
            : styles.navbarDark;
    const zemogaLogo =
        appState.navBarTheme === 'light' ? ZemogaLogoLight : ZemogaLogoDark;

    const fixedNavbarClasses = cx({
        [styles.fixedNavbarContainer]: true,
        [styles.visible]: isVisible,
        [styles.fixedNavbarContainerFixed]: fixed,
    });

    const handleResize = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        handleResize();
    }, []);

    return (
        <div className={`${themeStyle} ${fixedNavbarClasses}`}>
            <Slide down in appear>
                <nav className={`${styles.fixedNavbar}`}>
                    <div className={styles.fixedNavbarMenuContainer}>
                        <Link
                            to="/"
                            className={styles.fixedNavbarLogo}
                            onClick={closeMobileMenu}
                        >
                            <img
                                src={zemogaLogo}
                                alt="Zemoga"
                                className={styles.imgLogo}
                            />
                        </Link>
                        <div className={styles.menuIcon}>
                            <HamburgerButton
                                isOpen={isMenuOpen}
                                onClick={setIsMenuOpen}
                            />
                        </div>
                        <ul
                            className={`${styles.navMenu} ${isMenuOpen &&
                                styles.active}`}
                        >
                            <li className={styles.navItem}>
                                <Link
                                    to="/about-us/"
                                    className={styles.navLinks}
                                    onClick={closeMobileMenu}
                                >
                                    About Us
                                </Link>
                            </li>
                            <li className={styles.navItem}>
                                <Link
                                    to="/work/"
                                    className={styles.navLinks}
                                    onClick={closeMobileMenu}
                                >
                                    Work
                                </Link>
                            </li>
                            <li className={styles.navItem}>
                                <Link
                                    to="/culture/"
                                    className={styles.navLinks}
                                    onClick={closeMobileMenu}
                                >
                                    Culture
                                </Link>
                            </li>
                            <li className={styles.navItem}>
                                <Link
                                    to="/insights/"
                                    className={styles.navLinks}
                                    onClick={closeMobileMenu}
                                >
                                    Insights
                                </Link>
                            </li>
                            <li className={styles.navItem}>
                                <Link
                                    to="/careers/"
                                    className={styles.navLinks}
                                    onClick={closeMobileMenu}
                                >
                                    Careers
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </Slide>
        </div>
    );
}

FixedNavbar.defaultProps = {
    isVisible: true,
    fixed: false,
};

FixedNavbar.propTypes = {
    isVisible: PropTypes.bool,
    fixed: PropTypes.bool,
};

export default FixedNavbar;
