import React, { useState, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import cx from 'classnames';
import UpLoadIcon from '@components/Icons/upload.svg';
import Trash from '@components/Icons/trash.svg';
import Input from '../Input';
import FormHelperText from '../../FormHelperText/FormHelperText';
import styles from './input-file-formik.module.scss';

const InputFileFormik = ({ ...props }) => {
    const [, meta] = useField(props);
    const { setFieldValue } = useFormikContext();
    const { label: initialLabel, name } = props;
    const [labelMessage, setLabelMessage] = useState(initialLabel);
    const classes = cx(styles.primary);

    useEffect(() => {
        setLabelMessage(initialLabel);
    }, []);

    const createFile = file => {
        const reader = new FileReader();
        reader.onload = e => {
            setFieldValue('cvData', e.target.result);
        };
        reader.readAsDataURL(file);
    };

    const addFile = e => {
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        setLabelMessage(files[0].name);
        createFile(files[0]);
        setFieldValue('cv', e.currentTarget.files);
    };

    const hasError = meta.error ? styles.inputError : '';

    const removeFile = e => {
        e.preventDefault();
        setLabelMessage(initialLabel);
        setFieldValue('cv', null);
    };

    return (
        <>
            <label htmlFor={name}>
                <p className={`${classes} ${hasError}`}>
                    {`"${labelMessage}"`}
                    {labelMessage !== initialLabel ? (
                        <Trash onClick={removeFile} className={styles.icon} />
                    ) : (
                        <UpLoadIcon className={styles.icon} />
                    )}
                </p>
            </label>
            <Input
                className={styles.hideInput}
                id={name}
                {...props}
                onChange={addFile}
            />
            {meta.touched && (
                <FormHelperText
                    type="error"
                    message={meta.error}
                    className="fileError"
                />
            )}
        </>
    );
};

export default InputFileFormik;
