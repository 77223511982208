import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
} from 'react';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import PropTypes from 'prop-types';

import ZemogaLogoAtom from '@assets/images/logo-atom.svg';
import ZemogaLogoDark from '@assets/images/logo-dark.svg';
import ZemogaLogoLight from '@assets/images/logo-light.svg';

import HamburgerButton from '@components/HamburgerButton';
import ApplicationContext from '@contexts/ApplicationContext';
import FixedNavbar from './FixedNavbar';
import Menu from './Menu';
import styles from './navbar.module.scss';

const minHeightToSetScrolledMobile = 60;
const minHeightToSetScrolledWideScreen = 85;

function Navbar({ fixed }) {
    const breakpoints = useBreakpoint();

    const minHeightToSetScrolled = breakpoints.md
        ? minHeightToSetScrolledWideScreen
        : minHeightToSetScrolledMobile;

    const { appState } = useContext(ApplicationContext);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [scrolledStyle, setScrolledStyle] = useState('');
    const [y, setY] = useState(0);

    const detectZemogaLogo = useCallback(() => {
        // For Mobile it will be always dark logo
        if (isMenuOpen || !breakpoints.md) {
            return ZemogaLogoDark;
        }
        return ZemogaLogoLight;
    }, [isMenuOpen, breakpoints]);

    const [ZemogaLogoComplete, setZemogaLogoComplete] = useState(
        detectZemogaLogo
    );

    const navbarStickyStyle = cx({
        [styles.navbarSticky]: true,
        [styles.open]: isMenuOpen,
        [styles.navbarHidden]: isHidden,
    });

    const ZemogaLogo = breakpoints.md ? ZemogaLogoAtom : ZemogaLogoComplete;

    /* eslint-disable-next-line no-unused-vars */
    const navBarRef = useRef(null);
    /* eslint-disable-next-line no-unused-vars */
    const logoRef = useRef(null);
    /* eslint-disable-next-line no-unused-vars */
    const hamburgerRef = useRef(null);

    const isMenuOpenRef = useRef(isMenuOpen);
    isMenuOpenRef.current = isMenuOpen;

    const toggleMenu = open => {
        setIsMenuOpen(open);
    };

    const handleScroll = debounce(() => {
        const scrollPos = window.scrollY || window.scrollTop || 0;

        if (scrollPos > minHeightToSetScrolled) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
            toggleMenu(false);
        }

        // This logic where the navbar menu is hidden is just for mobile
        if (!breakpoints.md) {
            if (y >= scrollPos) {
                // UP
                setIsHidden(false);
            } else if (y < scrollPos) {
                // DOWN
                setIsHidden(true);
            }
        }
        setY(scrollPos);
    }, 100);

    const closeExpandedMenu = () => {
        setIsMenuOpen(false);
        handleScroll();
        document.querySelector('body').classList.remove('navbar-menu-open');
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        const style = isScrolled ? styles.scrolled : styles.noScrolled;
        setScrolledStyle(style);
    }, [isScrolled]);

    useEffect(() => {
        const logo = detectZemogaLogo();
        setZemogaLogoComplete(logo);
    }, [isMenuOpen]);

    useEffect(() => {
        let asyncBodyTagChange = null;
        if (isMenuOpen) {
            document.querySelector('body').classList.add('navbar-menu-open');
        } else {
            asyncBodyTagChange = setTimeout(() => {
                if (!isMenuOpenRef.current) {
                    document
                        .querySelector('body')
                        .classList.remove('navbar-menu-open');
                }
            }, 2500);
        }
        return () => clearTimeout(asyncBodyTagChange);
    }, [isMenuOpen]);

    return (
        <>
            {breakpoints.md && (
                <FixedNavbar
                    theme={appState.navBarTheme}
                    isVisible={!isScrolled}
                    fixed={fixed}
                />
            )}
            <div
                className={`${scrolledStyle} ${navbarStickyStyle}`}
                ref={navBarRef}
            >
                <nav className={`${styles.navbar}`}>
                    <div className={styles.navbarLogo} ref={logoRef}>
                        <Link to="/" onClick={closeExpandedMenu}>
                            <img
                                src={ZemogaLogo}
                                alt="Zemoga"
                                className={styles.imgLogo}
                            />
                        </Link>
                    </div>
                    <div className={styles.navbarContainer}>
                        <div className={styles.menuIcon} ref={hamburgerRef}>
                            <HamburgerButton
                                isVisible={isScrolled}
                                isOpen={isMenuOpen}
                                onClick={toggleMenu}
                            />
                        </div>
                        <Menu
                            className={styles.navMenuContainer}
                            isMenuOpen={isMenuOpen}
                            type="fullScreen"
                            onLinkClicked={closeExpandedMenu}
                        />
                    </div>
                </nav>
            </div>
        </>
    );
}

Navbar.defaultProps = {
    fixed: false,
};

Navbar.propTypes = {
    fixed: PropTypes.bool,
};

export default Navbar;
