/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState, useContext, useLayoutEffect } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import PropTypes from 'prop-types';
import { ParallaxContext, Parallax } from 'react-scroll-parallax';
import Footer from '../Footer';
import FormulaAnnouncementBanner from '../FormulaAnnouncementBanner';
import Header from '../Header';
import loadFonts from './typekit';
import ApplicationContext, {
    initialAppContext,
} from '../../contexts/ApplicationContext';

const Layout = ({
    children,
    navBarExternalBg,
    navBarTheme,
    footerCompact,
    disableFooterParallax,
    fixedHeader,
}) => {
    const parallaxController = useContext(ParallaxContext);
    const breakpoints = useBreakpoint();
    const footerParallaxIsDisabled = !breakpoints.md || disableFooterParallax;
    const [appState, setAppState] = useState({
        ...initialAppContext,
        navBarExternalBg,
        navBarTheme,
    });

    useEffect(() => {
        loadFonts();
    }, []);

    useLayoutEffect(() => {
        setAppState(currentState => ({
            ...currentState,
            navBarTheme,
        }));

        // IMPORTANT: this is just a workaround to properlly update parallax
        // wrapped components, there is an issue related to the scroll
        // position when gatsby load the pages cuasing the parallax not to work
        // as expected, more info can be found here:
        // https://github.com/jscottsmith/react-scroll-parallax/issues/65
        if (parallaxController) {
            window.requestAnimationFrame(() => {
                parallaxController.update();
            });
        }
    }, [navBarTheme, parallaxController]);

    return (
        <>
            <ApplicationContext.Provider value={{ appState, setAppState }}>
                <Header fixed={fixedHeader} />
                <main>{children}</main>
                <Parallax
                    y={[0, -35]}
                    disabled={footerParallaxIsDisabled}
                    styleInner={{
                        background: '#fff',
                    }}
                >
                    <Footer
                        isCompact={footerCompact}
                        style={{
                            paddingBottom: footerParallaxIsDisabled
                                ? undefined
                                : 0,
                        }}
                    />
                </Parallax>
                <FormulaAnnouncementBanner />
            </ApplicationContext.Provider>
            <div id="modal-root" />
        </>
    );
};

Layout.defaultProps = {
    navBarExternalBg: true,
    navBarTheme: 'light',
    footerCompact: false,
    disableFooterParallax: true,
    fixedHeader: false,
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    navBarExternalBg: PropTypes.bool,
    footerCompact: PropTypes.bool,
    disableFooterParallax: PropTypes.bool,
    navBarTheme: PropTypes.oneOf(['dark', 'light']),
    fixedHeader: PropTypes.bool,
};

export default Layout;
