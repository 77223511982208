import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import debounce from 'lodash/debounce';
import cx from 'classnames';
import useRect from '@hooks/useRect';
import styles from './hamburger-button.module.scss';

const THEMES = {
    dark: 'dark',
    light: 'light',
};

const createOnScrollHandler = ({ x, y, setTheme }) => () => {
    // The trick here is to place the hit point just below the hamburger menu,
    // so it doesn't intersect the HBM itself
    const element = document.elementFromPoint(x, y + 70);

    if (!element) return;

    const elementTheme = element && THEMES[element.dataset.theme];
    const closestElement = element.closest('[data-theme]');
    const closestElementTheme =
        closestElement && THEMES[closestElement.dataset.theme];
    const theme = elementTheme || closestElementTheme;

    if (theme) {
        setTheme(theme);
    }
};

const HamburgerButton = ({ isOpen, isVisible, onClick }) => {
    const breakpoints = useBreakpoint();
    const [theme, setTheme] = useState('light');
    const hbm = useRef(null);
    const hbmRect = useRect(hbm);
    const themeStyle = isOpen ? styles.dark : styles[theme];

    const toggleHamburgerButton = () => {
        onClick(!isOpen);
    };

    const classes = cx({
        [themeStyle]: true,
        [styles.hamburgerButton]: true,
        [styles.open]: isOpen,
        [styles.closed]: !isOpen,
        [styles.visible]: isVisible || !breakpoints.md,
    });

    useEffect(() => {
        const { x, y } = hbmRect;
        const onScrollHandler = debounce(
            createOnScrollHandler({ x, y, setTheme }),
            100
        );

        window.addEventListener('scroll', onScrollHandler);

        return () => window.removeEventListener('scroll', onScrollHandler);
    }, []);

    return (
        <div
            ref={hbm}
            data-testid="hamburguer-button"
            role="button"
            tabIndex={0}
            className={classes}
            onClick={toggleHamburgerButton}
            onKeyPress={toggleHamburgerButton}
        >
            <div className={styles.barTop} />
            <div className={styles.barMiddle} />
            <div className={styles.barBottom} />
        </div>
    );
};

HamburgerButton.defaultProps = {
    isVisible: false,
    isOpen: false,
};

HamburgerButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    isVisible: PropTypes.bool,
};

export default HamburgerButton;
