import React from 'react';
import Check from '@components/Icons/check.svg';
import { useField, Field } from 'formik';
import propTypes from 'prop-types';
import Text from '@components/core/Text';
import FormHelperText from '@components/core/FormHelperText/FormHelperText';
import styles from './input-checkbox-formik.module.scss';

// TODO: How to improve this component
const InputCheckboxFormik = ({ name, value, id, ...rest }) => {
    const [, meta] = useField({ name, value, id, rest });
    return (
        <>
            <div className={styles.container}>
                <label htmlFor={`${id}-yes`} className={styles.checkbox}>
                    <Field
                        id={`${id}-yes`}
                        type="radio"
                        name={name}
                        value="Yes"
                        className={styles.box}
                    />
                    {value === 'Yes' && <Check />}
                </label>
                <Text
                    className={styles.textContainer}
                    as="p"
                    size="paragraphSm"
                >
                    Yes
                </Text>
                <label htmlFor={`${id}-no`} className={styles.checkbox}>
                    <Field
                        id={`${id}-no`}
                        type="radio"
                        name={name}
                        value="No"
                        className={styles.box}
                    />
                    {value === 'No' && <Check />}
                </label>
                <Text
                    className={styles.textContainer}
                    as="p"
                    size="paragraphSm"
                >
                    No
                </Text>
            </div>
            <div className={styles.error}>
                {meta.touched && (
                    <FormHelperText
                        color="red"
                        type="errorCheckbox"
                        message={meta.error}
                    />
                )}
            </div>
        </>
    );
};

InputCheckboxFormik.propTypes = {
    name: propTypes.string.isRequired,
    id: propTypes.string.isRequired,
    value: propTypes.string,
};

InputCheckboxFormik.defaultProps = {
    value: '',
};
export default InputCheckboxFormik;
