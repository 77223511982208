import React, { useLayoutEffect, useRef } from 'react';
import Text from '@components/core/Text';
import CloseIcon from '@components/Icons/close-modal.svg';

import styles from './formula-announcement-banner.module.scss';

export default () => {
    const banner = useRef();
    const hsBanner = useRef();

    useLayoutEffect(() => {
        window.setTimeout(() => {
            hsBanner.current = document.querySelector(
                '#hs-eu-cookie-confirmation.hs-cookie-notification-position-bottom'
            );
            const shouldShowBanner = window.localStorage.getItem(
                'formula_banner'
            );

            if (parseInt(shouldShowBanner, 10) - new Date().getTime() > 0)
                return;
            if (hsBanner.current)
                hsBanner.current.classList.add('formula-announcement-open');
            if (banner.current) banner.current.classList.add(styles.open);
        }, 500);
    }, []);

    const closeBanner = () => {
        if (hsBanner.current)
            hsBanner.current.classList.remove('formula-announcement-open');
        if (banner.current) banner.current.classList.remove(styles.open);
        window.localStorage.setItem(
            'formula_banner',
            new Date().getTime() + 30000
        );
    };

    return (
        <aside ref={banner} className={styles.formulaAnnouncementBanner}>
            <Text size="paragraphXs" color="white">
                Zemoga is now Formula.Monks.{' '}
                <a
                    href="https://www.formula.co/s4-capital"
                    target="_blank"
                    rel="noreferrer"
                >
                    Read about the announcement here.
                </a>
            </Text>
            <CloseIcon onClick={closeBanner} className={styles.closeIcon} />
        </aside>
    );
};
