import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './text.module.scss';

const Text = ({
    as: Element,
    className,
    children,
    size,
    color,
    casing,
    normalLineHeight,
    ...rest
}) => {
    const classes = cx(
        {
            [styles.baseText]: true,
            [styles.normalLineHeight]: normalLineHeight,
            [styles[size]]: true,
            [styles[color]]: true,
            [styles[casing]]: !!casing,
        },
        className
    );
    return (
        <Element className={classes} {...rest}>
            {children}
        </Element>
    );
};

Text.propTypes = {
    as: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    size: PropTypes.oneOf([
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'paragraphXs',
        'paragraphMd',
        'paragraphSm',
    ]),
    color: PropTypes.oneOf([
        'black',
        'green',
        'white',
        'dark',
        'light',
        'gray',
        'red',
        'inherited',
        'lightGrey',
    ]),
    casing: PropTypes.oneOf(['uppercase', 'lowercase']),
    normalLineHeight: PropTypes.bool,
};

Text.defaultProps = {
    as: 'p',
    size: 'h1',
    color: 'black',
    className: '',
    casing: undefined,
    children: null,
    normalLineHeight: false,
};

export default Text;
