import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import cx from 'classnames';
import Section from '@components/core/Section';
import Text from '@components/core/Text';
import Grid, { Row, Column } from '@components/core/Grid';
import AnchorToSection from '@components/core/AnchorToSection';
import Eyebrow from '@components/core/Eyebrow';
import Instagram from '@assets/images/instagram.svg';
import LinkedIn from '@assets/images/linkedin.svg';
import Facebook from '@assets/images/facebook.svg';
import PartialNavLink from '@components/core/PartialNavLink';

import styles from './menu.module.scss';

function Menu({ isMenuOpen, className, type, onLinkClicked }) {
    const menuClasses = cx({
        [className]: true,
        [styles.menu]: true,
        [styles[type]]: true,
        [styles.active]: isMenuOpen,
        [styles.closed]: !isMenuOpen,
    });

    const textSizeTag = 'h4';

    return (
        <div className={menuClasses}>
            <svg
                version="1.1"
                viewBox="0 0 1255 833"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.imageCover}
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(0 -1.2048)">
                        <path
                            d="m290.9 362.43c-36.23 185.94 85.102 365.89 271 401.94 185.9 36.048 365.97-85.461 402.2-271.4 36.23-185.94-85.102-365.89-271-401.94-185.9-36.048-365.97 85.461-402.2 271.4zm402.01-270.42c185.36 35.943 306.34 215.37 270.21 400.77-36.125 185.39-215.67 306.55-401.03 270.61-185.36-35.943-306.34-215.37-270.21-400.77 36.125-185.39 215.67-306.55 401.03-270.61z"
                            fill="#fff"
                            fillRule="nonzero"
                            opacity=".2"
                        />
                        <polygon
                            points="1254.6 24.828 1255.2 25.444 495.33 834.48 494.67 833.87"
                            fill="#fff"
                            fillRule="nonzero"
                            opacity=".2"
                        />
                        <polygon
                            points="1019.1 0 1019.7 0.61683 235.12 834.27 234.46 833.66"
                            fill="#fff"
                            fillRule="nonzero"
                            opacity=".2"
                        />
                        <polygon
                            points="760.85 1.5134 761.51 2.1236 26.061 799.48 25.4 798.87"
                            fill="#fff"
                            fillRule="nonzero"
                            opacity=".2"
                        />
                        <polygon
                            points="870.86 154.12 731.41 302.23 735.35 305.93 874.8 157.82"
                            fill="#1037D7"
                        />
                        <polygon
                            points="7.1054e-15 823.88 120.36 693.3 123.67 696.35 3.3088 826.93"
                            fill="#10C64E"
                        />
                        <polygon
                            points="705.77 606.91 597.47 722.58 601.41 726.27 709.71 610.6"
                            fill="#F53030"
                        />
                    </g>
                </g>
            </svg>
            <Section className={styles.menuBoxSection}>
                <Grid>
                    <Row>
                        <Column className={styles.menuColumn}>
                            <div className={styles.itemsListContainer}>
                                <ul className={styles.itemsList}>
                                    <li className={styles.navItem}>
                                        <Link
                                            to="/about-us/"
                                            className={styles.navLinks}
                                            onClick={onLinkClicked}
                                            activeClassName={
                                                styles.navActiveLink
                                            }
                                        >
                                            <Text
                                                as={textSizeTag}
                                                size={textSizeTag}
                                                color="inherited"
                                            >
                                                About Us
                                            </Text>
                                        </Link>
                                    </li>
                                    <li className={styles.navItem}>
                                        <Link
                                            to="/work/"
                                            className={styles.navLinks}
                                            onClick={onLinkClicked}
                                            partiallyActive
                                            activeClassName={
                                                styles.navActiveLink
                                            }
                                        >
                                            <Text
                                                as={textSizeTag}
                                                size={textSizeTag}
                                                color="inherited"
                                            >
                                                Work
                                            </Text>
                                        </Link>
                                    </li>
                                    <li className={styles.navItem}>
                                        <Link
                                            to="/culture/"
                                            className={styles.navLinks}
                                            onClick={onLinkClicked}
                                            activeClassName={
                                                styles.navActiveLink
                                            }
                                        >
                                            <Text
                                                as={textSizeTag}
                                                size={textSizeTag}
                                                color="inherited"
                                            >
                                                Culture
                                            </Text>
                                        </Link>
                                    </li>
                                    <li className={styles.navItem}>
                                        <Link
                                            to="/insights/"
                                            className={styles.navLinks}
                                            onClick={onLinkClicked}
                                            partiallyActive
                                            activeClassName={
                                                styles.navActiveLink
                                            }
                                        >
                                            <Text
                                                as={textSizeTag}
                                                size={textSizeTag}
                                                color="inherited"
                                            >
                                                Insights
                                            </Text>
                                        </Link>
                                    </li>
                                    <li className={styles.navItem}>
                                        <PartialNavLink
                                            to="/careers/"
                                            onClick={onLinkClicked}
                                            partialPaths={['/jobs']}
                                            className={styles.navLinks}
                                            activeClassName={
                                                styles.navActiveLink
                                            }
                                        >
                                            <Text
                                                as={textSizeTag}
                                                size={textSizeTag}
                                                color="inherited"
                                            >
                                                Careers
                                            </Text>
                                        </PartialNavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.contactSection}>
                                <AnchorToSection
                                    toPath="/contact-us/"
                                    sizeIcon="6"
                                    className={styles.letsTalk}
                                >
                                    <Text
                                        as={textSizeTag}
                                        size={textSizeTag}
                                        color="inherited"
                                    >
                                        Let&apos;s Talk
                                    </Text>
                                </AnchorToSection>
                                <Eyebrow
                                    className={styles.eyebrow}
                                    casing="uppercase"
                                >
                                    FOLLOW US
                                </Eyebrow>
                                <div className={styles.socialMedia}>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.instagram.com/zemoga_inc/"
                                        className={styles.socialMediaLink}
                                    >
                                        <img
                                            src={Instagram}
                                            alt="Zemoga Instagram"
                                        />
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.linkedin.com/company/zemoga-inc/"
                                        className={styles.socialMediaLink}
                                    >
                                        <img
                                            src={LinkedIn}
                                            alt="Zemoga Linkedin"
                                        />
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.facebook.com/Zemoga/"
                                        className={styles.socialMediaLink}
                                    >
                                        <img
                                            src={Facebook}
                                            alt="Zemoga Facebook"
                                        />
                                    </a>
                                </div>
                            </div>
                        </Column>
                    </Row>
                </Grid>
            </Section>
        </div>
    );
}

Menu.defaultProps = {
    isMenuOpen: false,
    className: '',
};

Menu.propTypes = {
    className: PropTypes.string,
    isMenuOpen: PropTypes.bool,
    onLinkClicked: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['fullScreen', 'horizontal']).isRequired,
};

export default Menu;
