import React from 'react';
import cx from 'classnames';
import propTypes from 'prop-types';
import styles from './input.module.scss';

const Input = ({
    as: Element = 'input',
    className,
    variant,
    size,
    ...rest
}) => {
    const classes = cx(
        styles[variant] || styles.primary,
        styles[size] || styles.fluid,
        className
    );

    return <Element className={classes} {...rest} />;
};

Input.propTypes = {
    as: propTypes.string,
    className: propTypes.string,
    variant: propTypes.oneOf(['primary', 'secondary']),
    size: propTypes.oneOf(['block', 'fluid']),
};

Input.defaultProps = {
    as: 'input',
    className: '',
    variant: 'primary',
    size: 'fluid',
};

export default Input;
