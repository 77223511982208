// Typekit font loader
export default function loadFonts() {
    // Current font: Proxima Nova
    const TYPEKIT_ID = 'mzz8qnw';
    let loaded = false;
    const typekit = document.createElement('script');
    const script = document.getElementsByTagName('script')[0];
    let rs;

    typekit.type = 'text/javascript';
    typekit.src = `//use.typekit.net/${TYPEKIT_ID}.js`;
    typekit.async = true;
    // eslint-disable-next-line no-multi-assign
    typekit.onload = typekit.onreadystatechange = () => {
        rs = window.readyState;

        if (loaded || (rs && rs !== 'complete' && rs !== 'loaded')) {
            return;
        }
        loaded = true;
        try {
            window.Typekit.load({
                kitId: TYPEKIT_ID,
            });
        } catch (e) {
            // Empty Catch
        }
    };

    // Insert script tag
    script.parentNode.insertBefore(typekit, script);
}
