import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import cx from 'classnames';
import ArrowRight from '@components/Icons/arrow-right.svg';
import styles from './anchor-to.module.scss';

const AnchorToSection = ({
    children,
    toPath,
    sizeIcon,
    className,
    style,
    animatedInHover,
}) => {
    const isLink = !!toPath;
    const Tag = isLink ? Link : 'div';
    const props = isLink
        ? {
              to: toPath,
          }
        : {};
    const arrowStyleClass =
        styles[`anchorToSection${sizeIcon}X`] || styles.anchorToSection6X;

    const classes = cx({
        [className]: true,
        [styles.anchorToSection]: true,
        [styles.animated]: animatedInHover,
    });

    return (
        <Tag {...props} className={classes} style={style}>
            <div className={styles.wrapper}>
                {children}
                <ArrowRight className={arrowStyleClass} />
            </div>
        </Tag>
    );
};

AnchorToSection.propTypes = {
    children: PropTypes.node.isRequired,
    toPath: PropTypes.string,
    sizeIcon: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
    animatedInHover: PropTypes.bool,
};

AnchorToSection.defaultProps = {
    toPath: undefined,
    sizeIcon: '6',
    className: '',
    style: {},
    animatedInHover: false,
};

export default AnchorToSection;
