import React from 'react';
import cx from 'classnames';
import propTypes from 'prop-types';
import styles from './button.module.scss';

const Button = ({
    as: Element = 'button',
    className,
    children,
    variant,
    size,
    ...rest
}) => {
    const classes = cx(
        styles[variant] || styles.primary,
        styles[size] || styles.normalWidth,
        className
    );

    return (
        <Element className={classes} {...rest}>
            {children}
        </Element>
    );
};

Button.propTypes = {
    as: propTypes.string,
    className: propTypes.string,
    children: propTypes.node.isRequired,
    variant: propTypes.oneOf(['primary', 'secondary', 'dark', 'ghost']),
    size: propTypes.oneOf(['block', 'fluid']),
};

Button.defaultProps = {
    as: 'button',
    className: '',
    variant: 'primary',
    size: 'fluid',
};

export default Button;
