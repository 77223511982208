import React from 'react';
import propTypes from 'prop-types';
import Text from '@components/core/Text';
import styles from './form-helper-text.module.scss';

const FormHelperText = ({ type, message, className }) => {
    const color = type === 'success' ? 'green' : 'red';
    return (
        <Text
            color={color}
            as="p"
            size="paragraphXs"
            className={`${styles[type]} ${styles[className]}`}
        >
            {message}
        </Text>
    );
};

FormHelperText.propTypes = {
    type: propTypes.oneOf(['error', 'success']),
    message: propTypes.string,
    className: propTypes.string,
};

FormHelperText.defaultProps = {
    type: '',
    message: '',
    className: '',
};

export default FormHelperText;
