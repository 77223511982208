import React from 'react';
import propTypes from 'prop-types';
import cx from 'classnames';
import styles from './section.module.scss';

const Section = React.forwardRef(
    (
        { as: Element, children, variant, header, className, style, dataTheme },
        ref
    ) => {
        const classes = cx(
            {
                [styles[variant]]: true,
            },
            header && styles.headerSection,
            className
        );
        return (
            <Element
                ref={ref}
                className={classes}
                style={style}
                data-theme={dataTheme}
            >
                {children}
            </Element>
        );
    }
);

Section.propTypes = {
    children: propTypes.node.isRequired,
    variant: propTypes.oneOf(['light', 'dark']),
    className: propTypes.string,
    style: propTypes.objectOf(propTypes.any),
    as: propTypes.string,
    header: propTypes.bool,
    dataTheme: propTypes.oneOf(['light', 'dark']),
};

Section.defaultProps = {
    variant: 'light',
    dataTheme: 'light',
    style: {},
    className: '',
    as: 'section',
    header: false,
};

export default Section;
