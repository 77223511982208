import React from 'react';
import { useField } from 'formik';
import propTypes from 'prop-types';
import Input from '../Input';
import FormHelperText from '../../FormHelperText/FormHelperText';
import styles from './input-formik.module.scss';

const InputFormik = ({ className, ...props }) => {
    const [field, meta] = useField(props);
    const hasError = meta.error && styles.inputError;
    return (
        <>
            <Input
                className={`${className} ${hasError}`}
                {...field}
                {...props}
            />
            {meta.touched && (
                <FormHelperText type="error" message={meta.error} />
            )}
        </>
    );
};

InputFormik.propTypes = {
    className: propTypes.string,
};

InputFormik.defaultProps = {
    className: '',
};

export default InputFormik;
