import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

const getDefaultStyle = (delay, fromOpacity, opacityDuration) => ({
    transition: `opacity ${opacityDuration}ms ease-out`,
    opacity: fromOpacity,
    transitionDelay: delay,
});

const getTransitionStyles = (initialPosition, fromOpacity) => ({
    appear: {
        opacity: fromOpacity,
    },
    entered: { opacity: 1 },
});

const Fade = ({
    appear,
    children,
    className,
    delay,
    in: inProp,
    opacityDuration,
    fromOpacity,
}) => {
    return (
        <Transition in={inProp} appear={appear} timeout={0}>
            {state => (
                <div
                    className={className}
                    style={{
                        ...getDefaultStyle(delay, fromOpacity, opacityDuration),
                        ...getTransitionStyles(fromOpacity)[state],
                    }}
                >
                    {children}
                </div>
            )}
        </Transition>
    );
};

Fade.propTypes = {
    appear: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    delay: PropTypes.string,
    in: PropTypes.bool,
    opacityDuration: PropTypes.number,
    fromOpacity: PropTypes.number,
};

Fade.defaultProps = {
    appear: true,
    className: '',
    delay: '0ms',
    in: true,
    opacityDuration: 150,
    fromOpacity: 0,
};

export default Fade;
