import React from 'react';
import Text from '@components/core/Text';
import Eyebrow from '@components/core/Eyebrow';
import Section from '@components/core/Section';
import { useStaticQuery, graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Grid, { Column, Row } from '@components/core/Grid';
import AnchorToSection from '@components/core/AnchorToSection';
import SubscriptionBox from '@components/core/SubscriptionBox';
import PropTypes from 'prop-types';
import LocationInfo from './location/LocationInfo';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';

import styles from './footer.module.scss';

const Footer = ({ isCompact, dataTheme, style }) => {
    const breakpoints = useBreakpoint();
    const {
        result: { locations },
    } = useStaticQuery(graphql`
        query GetAllLocations {
            result: allMarkdownRemark(
                filter: { fields: { slug: { regex: "/locations/" } } }
            ) {
                locations: nodes {
                    fields {
                        slug
                    }
                    content: frontmatter {
                        city
                        address
                        office
                        complement
                        phone
                    }
                }
            }
        }
    `);

    return (
        <footer
            data-theme={dataTheme}
            className={!isCompact ? styles.footer : styles.footerCompact}
            style={style}
        >
            <Section>
                <Grid>
                    <div>
                        {!isCompact && (
                            <Row>
                                <Column>
                                    <Eyebrow
                                        casing="uppercase"
                                        size="paragraphSm"
                                        className={styles.eyeBrow}
                                    >
                                        Contact Us
                                    </Eyebrow>
                                    <AnchorToSection
                                        sizeIcon={breakpoints.sm ? '11' : '6'}
                                        className={styles.anchorToSection}
                                        toPath="/contact-us"
                                    >
                                        <Text as="h2" size="h2">
                                            Let&apos;s talk!
                                        </Text>
                                    </AnchorToSection>
                                </Column>
                            </Row>
                        )}
                        {breakpoints.md && !isCompact && (
                            <Row>
                                <Column>
                                    <div className={styles.footerLocations}>
                                        {locations.map(({ content }) => {
                                            return (
                                                <LocationInfo
                                                    key={content.city}
                                                    location={content}
                                                />
                                            );
                                        })}
                                    </div>
                                </Column>
                            </Row>
                        )}
                        <Row>
                            <Column>
                                <div className={styles.footerEndBox}>
                                    <div className={styles.footerEndBoxLeft}>
                                        <PrivacyPolicy />
                                        <Text
                                            as="p"
                                            size="paragraphSm"
                                            className={
                                                styles.footerEndBoxCopyright
                                            }
                                        >
                                            {`Copyright \u00A9 ${new Date().getFullYear()} Zemoga.com`}
                                        </Text>
                                    </div>
                                    <div
                                        className={styles.footerEndBoxSubscribe}
                                    >
                                        {!breakpoints.md && (
                                            <p
                                                className={
                                                    styles.footerEndBoxSubscribeCopy
                                                }
                                            >
                                                Subscribe to our newsletter
                                            </p>
                                        )}
                                        <SubscriptionBox />
                                    </div>
                                </div>
                            </Column>
                        </Row>
                    </div>
                </Grid>
            </Section>
        </footer>
    );
};

Footer.defaultProps = {
    isCompact: false,
    dataTheme: 'light',
    style: {},
};

Footer.propTypes = {
    isCompact: PropTypes.bool,
    dataTheme: PropTypes.oneOf(['light', 'dark']),
    style: PropTypes.objectOf(PropTypes.any),
};

export default Footer;
