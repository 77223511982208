import React from 'react';
import propTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import cx from 'classnames';
import styles from './Row.module.scss';

const SPACE_BETWEEN = 'space-between';
const SPACE_AROUND = 'space-around';
const FLEX_START = 'flex-start';
const FLEX_END = 'flex-end';
const generateDirection = (breakpoint, dirDefault, xs, sm, md, l, xl) => {
    if (xl && breakpoint.xl) return [styles[xl]];
    if (l && breakpoint.l) return [styles[l]];
    if (md && breakpoint.md) return [styles[md]];
    if (sm && breakpoint.sm) return [styles[sm]];

    return [styles[dirDefault]];
};

const Row = ({
    children,
    dirDefault,
    xs,
    sm,
    md,
    l,
    xl,
    className,
    justifyContent,
}) => {
    const breakpoint = useBreakpoint();
    const directionClass = generateDirection(
        breakpoint,
        dirDefault,
        xs,
        sm,
        md,
        l,
        xl
    );
    const classes = cx(
        directionClass,
        {
            'space-between': justifyContent === SPACE_BETWEEN,
            'space-around': justifyContent === SPACE_AROUND,
            'flex-end': justifyContent === FLEX_END,
            'flex-start': justifyContent === FLEX_START,
        },
        className
    );
    return <div className={classes}>{children}</div>;
};

Row.propTypes = {
    children: propTypes.oneOfType([
        propTypes.arrayOf(propTypes.node),
        propTypes.node,
    ]).isRequired,
    dirDefault: propTypes.string,
    xs: propTypes.oneOf(['col', 'row', '']),
    sm: propTypes.oneOf(['col', 'row', '']),
    md: propTypes.oneOf(['col', 'row', '']),
    l: propTypes.oneOf(['col', 'row', '']),
    xl: propTypes.oneOf(['col', 'row', '']),
    className: propTypes.string,
    justifyContent: propTypes.oneOf([
        'space-between',
        'space-around',
        'flex-start',
        'flex-end',
        '',
    ]),
};

Row.defaultProps = {
    className: '',
    dirDefault: 'row',
    xs: '',
    sm: '',
    md: '',
    l: '',
    xl: '',
    justifyContent: '',
};

// eslint-disable-next-line import/prefer-default-export
export { Row };
