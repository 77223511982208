import React from 'react';
import propTypes from 'prop-types';
import cx from 'classnames';
import styles from './Grid.module.scss';

const Grid = ({ children, spacing, className, maxWidthFull, style }) => {
    const classes = cx(
        {
            [styles.grid]: !maxWidthFull,
            [styles.gridFull]: maxWidthFull,
            [styles.spacing]: !maxWidthFull && spacing,
        },
        className
    );

    return (
        <div className={classes} style={style}>
            {children}
        </div>
    );
};

Grid.propTypes = {
    children: propTypes.node.isRequired,
    spacing: propTypes.bool,
    className: propTypes.string,
    style: propTypes.objectOf(propTypes.any),
    maxWidthFull: propTypes.bool,
};

Grid.defaultProps = {
    spacing: true,
    className: '',
    style: {},
    maxWidthFull: false,
};

export default Grid;
