import React from 'react';
import PropTypes from 'prop-types';
import Text from '@components/core/Text';
import styles from './location.module.scss';

const LocationInfo = ({
    location: { city, address, office, complement, phone },
}) => {
    return (
        <div className={styles.location}>
            <Text as="p" size="paragraphMd" className={styles.locationCity}>
                {city}
            </Text>
            <Text as="p" size="paragraphSm" className={styles.locationContent}>
                {address}
            </Text>
            <Text as="p" size="paragraphSm" className={styles.locationContent}>
                {office}
            </Text>
            <Text as="p" size="paragraphSm" className={styles.locationContent}>
                {complement}
            </Text>
            <Text as="p" size="paragraphSm" className={styles.locationContent}>
                {phone}
            </Text>
        </div>
    );
};
LocationInfo.propTypes = {
    location: PropTypes.shape({
        city: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        office: PropTypes.string,
        complement: PropTypes.string,
        phone: PropTypes.string.isRequired,
    }).isRequired,
};
export default LocationInfo;
