import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

const getDefaultStyle = (
    initialPosition,
    delay,
    fromOpacity,
    transformDuration,
    opacityDuration
) => ({
    transition: `transform ${transformDuration}ms ease-out, opacity ${opacityDuration}ms ease-out`,
    opacity: fromOpacity,
    transform: `translateY(${initialPosition})`,
    transitionDelay: delay,
});

const getTransitionStyles = (initialPosition, fromOpacity) => ({
    appear: {
        transform: `translateY(${initialPosition})`,
        opacity: fromOpacity,
    },
    entered: { transform: 'translateY(0)', opacity: 1 },
});

const Slide = ({
    appear,
    children,
    className,
    delay,
    down,
    in: inProp,
    transformDuration,
    opacityDuration,
    withOpacity,
}) => {
    const initialPosition = down ? '-100%' : '100%';
    const fromOpacity = withOpacity ? 0 : 1;
    return (
        <Transition in={inProp} appear={appear} timeout={0}>
            {state => (
                <div
                    className={className}
                    style={{
                        ...getDefaultStyle(
                            initialPosition,
                            delay,
                            fromOpacity,
                            transformDuration,
                            opacityDuration
                        ),
                        ...getTransitionStyles(initialPosition, fromOpacity)[
                            state
                        ],
                    }}
                >
                    {children}
                </div>
            )}
        </Transition>
    );
};

Slide.propTypes = {
    appear: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    delay: PropTypes.string,
    down: PropTypes.bool,
    in: PropTypes.bool,
    transformDuration: PropTypes.number,
    opacityDuration: PropTypes.number,
    withOpacity: PropTypes.bool,
};

Slide.defaultProps = {
    appear: true,
    className: '',
    delay: '0ms',
    down: false,
    in: true,
    transformDuration: 300,
    opacityDuration: 150,
    withOpacity: false,
};

export default Slide;
