import React, { useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Formik, Form } from 'formik';
import { InputFormik } from '@components/core/Input';
import Button from '@components/core/Button';
import Text from '@components/core/Text';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Check from '@components/Icons/check.svg';
import useCaptchaValidation from '@hooks/useCaptchaValidation';
import FormHelperText from '@components/core/FormHelperText/FormHelperText';
import styles from './subscription-box.module.scss';

const buttonVariantsMap = {
    dark: 'dark',
    primary: 'primary',
};
const FORM_ID = '9e85b803-0bf4-4d6b-861d-577c7c9548a4';

const SubscriptionBox = ({ variant, sentMessageColor }) => {
    const breakpoints = useBreakpoint();
    const [formFeedback, setFormFeedback] = useState();

    return (
        <Formik
            initialValues={{
                email: '',
            }}
            validationSchema={Yup.object({
                email: Yup.string()
                    .email('Invalid email address')
                    .required('An email is required'),
            })}
            onSubmit={async (values, { setStatus }) => {
                setFormFeedback({
                    type: 'success',
                    message: 'Please wait, we’re submitting your info.',
                });
                const onSubmit = ({ token }) => {
                    const fields = [
                        {
                            name: 'email',
                            value: values.email,
                        },
                    ];
                    const formData = {
                        fields,
                        formId: FORM_ID,
                        gToken: token,
                    };

                    fetch(
                        `${process.env.GATSBY_LAMBDA_HUBSPOT_CONNECTOR_API}/forms`,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(formData),
                        }
                    )
                        .then(response => response.json())
                        .then(data => {
                            const { status } = data;
                            if (status === 'error') {
                                setFormFeedback({
                                    type: 'error',
                                    message: 'Please try again later',
                                });
                            } else {
                                setStatus('sent');
                            }
                        });
                };

                const token = await useCaptchaValidation();
                onSubmit({ token });
            }}
        >
            {({ handleSubmit, status, errors, values }) => {
                return (
                    <>
                        {status === 'sent' ? (
                            <div className={styles.feedbackContainer}>
                                <Check
                                    className={styles.check}
                                    width={24}
                                    height={24}
                                />
                                <Text
                                    as="span"
                                    size="paragraphSm"
                                    color={sentMessageColor}
                                    className={styles.formFeedback}
                                >
                                    Please check your email to confirm your
                                    subscription.
                                </Text>
                            </div>
                        ) : (
                            <>
                                <Form
                                    className={styles.box}
                                    onSubmit={handleSubmit}
                                >
                                    <div>
                                        <InputFormik
                                            className={
                                                buttonVariantsMap[variant] ===
                                                buttonVariantsMap.primary
                                                    ? styles.inputPrimary
                                                    : styles.input
                                            }
                                            name="email"
                                            type="text"
                                            placeholder={
                                                breakpoints.sm
                                                    ? 'Subscribe to our newsletter'
                                                    : 'Put your email here'
                                            }
                                        />
                                    </div>
                                    <Button
                                        className={styles.button}
                                        variant={buttonVariantsMap[variant]}
                                        disabled={
                                            (errors && errors.email) ||
                                            values.email === ''
                                        }
                                        type="submit"
                                        size={
                                            breakpoints.md ? 'fluid' : 'block'
                                        }
                                    >
                                        <span>Subscribe</span>
                                    </Button>
                                </Form>
                                {formFeedback && (
                                    <FormHelperText
                                        type={formFeedback.type}
                                        message={formFeedback.message}
                                        className="right"
                                    />
                                )}
                            </>
                        )}
                    </>
                );
            }}
        </Formik>
    );
};

SubscriptionBox.propTypes = {
    variant: PropTypes.oneOf(['dark', 'primary']),
    sentMessageColor: PropTypes.string,
};

SubscriptionBox.defaultProps = {
    variant: 'dark',
    sentMessageColor: 'black',
};

export default SubscriptionBox;
